const state = {
    name: 0, // 姓名
    age: 0, // 年龄
    profileRecord : {},//档案列表记录
    propertyRecord:{},//物业管理档案列表记录
};
  
const mutations = {
    SET_NAME: (state, data) => {
      state.name = data;
    },
    SET_AGE:(state, data) => {
        state.age = data;
    },
    SET_PRORILERECORD : (state,data) =>{
        state.profileRecord = data
    },
    SET_PROPERTYRECORD : (state,data) =>{
        state.propertyRecord = data
    }
}
const actions = {}
  
export default {
    state,
    mutations,
    actions
}