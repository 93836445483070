import drag from './drag'

const install = function (Vue) {
  Vue.directive('el-drag-dialog', drag)
}

if (window.Vue) {
  window['el-drag-dialog'] = drag
  // eslint-disable-next-line no-undef
  Vue.use(install)
}

drag.install = install
export default drag
