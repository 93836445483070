const getters = {
    count: state => state.form.count,
    name: state => state.user.name,
    age: state => state.user.age,
    profileRecord: state => state.user.profileRecord, //居民管理-档案列表 详情
    propertyRecord: state => state.user.propertyRecord //物业管理-档案列表 详情


  }
  export default getters
  